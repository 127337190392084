import {
    Box,
    Button,
    Flex,
    Icon,
    IconButton,
    HStack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
    useClipboard
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { NavLink, useNavigate } from 'react-router-dom';
// Custom components
import Card from 'components/card/Card';
import * as React from 'react';
// Assets
import { MdCancel, MdCheckCircle, MdShare } from 'react-icons/md';
import { EventSummary } from '../models/EventSummary';


const columnHelper = createColumnHelper<EventSummary>();

// const columns = columnsDataCheck;
export default function EventsTable(props: { tableData: EventSummary[] }) {
	const navigate = useNavigate();
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [isMobile] = useMediaQuery("(max-width: 480px)");

    // Get empresa id from session storage
    const empresaId = sessionStorage.getItem('empresaId');
    const link = `http://www.tickazo.com/empresas/${empresaId}/events`;
    const { hasCopied, onCopy } = useClipboard(link);

	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('nombre', {
			id: 'nombre',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Titulo
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor((row) => {
			const localDate = new Date(row.eventStartDatetimeUtc);
			// convert to YYYY-MM-DD and HH:MM format
			const date = localDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
			const time = localDate.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
			return `${date} ${time}`;
		}, {
            id: 'startingTime',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                Comienza
                </Text>
            ),
            cell: (info) => {
                const startingTime = info.getValue();
            
                return (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {startingTime}
                </Text>
                );
            }
        }),		
        columnHelper.accessor((row) => {
			const localDate = new Date(row.eventEndDatetimeUtc);
			// convert to YYYY-MM-DD and HH:MM format
			const date = localDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
			const time = localDate.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
			return `${date} ${time}`;
		}, {
            id: 'endingTime',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                Finaliza
                </Text>
            ),
            cell: (info) => {
                const endingTime = info.getValue();
            
                return (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {endingTime}
                </Text>
                );
            }
        }),
        columnHelper.accessor((row) => `${row.entradasVendidas}/${row.entradasTotales}`, {
            id: 'ticketSalesRatio',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                Entradas (vendidas/restantes)
                </Text>
            ),
            cell: (info) => {
                const ticketSalesRatio = info.getValue();
            
                return (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {ticketSalesRatio}
                </Text>
                );
            }
        }),
        columnHelper.accessor('ingresos', {
			id: 'ingresos',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Ingresos
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('habilitado', {
			id: 'habilitado',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ESTADO
				</Text>
			),
			cell: (info) => (
			<Flex align='center'>
				<Icon
					w='24px'
					h='24px'
					me='5px'
					color={
                        info.getValue()
                        ? 'green.500'
                        : 'red.500'
					}
					as={
						info.getValue()
                        ? MdCheckCircle
						: MdCancel
					}
				/>
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{
                        info.getValue()
                        ? 'Habilitado'
                        : 'Deshabilitado'
                    }
				</Text>
			</Flex> 
			)
		}),
        // Show a button to get more details of the event
        columnHelper.accessor('id', {
            id: 'eventDetails',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                Más detalles
                </Text>
            ),
            cell: (info) => {
                const eventId = info.getValue();
            
                return (
                    <NavLink to={`/admin/eventos/${eventId}`}>
                        <Button
                            variant='brand'
                            fontSize='sm'
                        >
                            Más detalles
                        </Button>
                    </NavLink>
                );
            }
        }),
	];
	const table = useReactTable({
		data: defaultData,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Mis Eventos
				</Text>
                <HStack spacing={4}>
                {
                    isMobile
                    ? <IconButton 
                        variant='brand'
                        aria-label="Copy link" 
                        icon={<MdShare />} 
                        onClick={onCopy} 
                    /> 
                    : <Button
                        fontSize='sm'
                        variant='brand'
                        // w='100%'
                        // h='50'
                        // mb='24px'
                        onClick={onCopy}
                        >
                        {hasCopied ? "Copiado!" : "Copiar link de mis eventos"}
                    </Button>
                    }
                    <Button
                        variant='brand'
                        fontSize='sm'
						onClick={() => {
							navigate('/admin/eventos/crear');

							}
						}
                    >
                        {isMobile ? "+" : "+ Nuevo Evento"}
                    </Button>
                </HStack>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 11).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</Box>
		</Card>
	);
}