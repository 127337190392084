import { useEffect } from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    NumberInput,
    NumberInputField,
    useToast,
    Textarea,
    FormHelperText,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import Event from "../models/Event";
import Predio from "../models/Predio";
import Categoria from "../models/Categoria";
import TimePicker from "react-time-picker";
import ImageUploader from "./ImageUploader";
import useFormSubmit from "../hooks/useFormSubmit";
import DateField from "./DateField";
import { useNavigate } from "react-router-dom";

export interface EventFormProps {
    event?: Event;
    predios: Predio[];
    categorias: Categoria[];
    [x: string]: any;
}

interface FormValues {
    [key: string]: any;
    nombre: string;
    predio: string | number;
    categoria: string | number;
    edad: string | number;
    fechaStart: string;
    horaStart: string;
    fechaEnd: string;
    horaEnd: string;
    desc: string;
    imgHeader: string;
    imgPoster: string;
}

export default function EventForm(props: EventFormProps) {

    const toast = useToast();

    const navigate = useNavigate();

    const { event, predios, categorias } = props;

    // const [calendarVisible, setCalendarVisible] = useState(false);

    const isUpdateMode = event !== null;

    const { submitForm, loading, error } = useFormSubmit(isUpdateMode ? 'update' : 'create', isUpdateMode ? event.id : null);

    const initialValues: FormValues = {
        nombre: '',
        predio: '',
        categoria: '',
        edad: '',
        fechaStart: '',
        horaStart: '',
        fechaEnd: '',
        horaEnd: '',
        desc: '',
        imgHeader: '',
        imgPoster: '',
        habilitado: ''
    };

    if (isUpdateMode) {

        // get event start datetime in local timezone
        const eventStartDatetimeUtc = new Date(event.eventStartDatetimeUtc);
        // convert to local date but to format YYYY-MM-DD
        const startTime = eventStartDatetimeUtc.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }).substring(0, 5);
        
        // get event end datetime in local timezone
        const eventEndDatetimeUtc = new Date(event.eventEndDatetimeUtc);
        const endTime = eventEndDatetimeUtc.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }).substring(0, 5);

        initialValues.nombre = event.nombre;
        initialValues.predio = event.predio.id;
        initialValues.categoria = event.categoria.id;
        initialValues.edad = event.edad;
        initialValues.fechaStart = event.eventStartDatetimeUtc.substring(0, 10);
        initialValues.horaStart = startTime;
        initialValues.fechaEnd = event.eventEndDatetimeUtc.substring(0, 10);
        initialValues.horaEnd = endTime;
        initialValues.desc = event.desc;
        initialValues.imgHeader = event.imgHeader;
        initialValues.imgPoster = event.imgPoster;
        initialValues.habilitado = event.habilitado ? 'true' : 'false';
    }

    useEffect(() => {
        if (error) {
            toast({
                title: isUpdateMode ? 'Error al actualizar el evento' : 'Error al crear el evento',
                description: 'Por favor, intenta nuevamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [error, isUpdateMode, toast]);

    const getChangedValues = (values: FormValues, event: any): Partial<FormValues> => {
        let changedValues: Partial<FormValues> = {};
        if (isUpdateMode) {
            for (const key in values) {
                if (values[key] !== (event as any)[key]) {
                    changedValues[key] = values[key];
                }
            }
        } else {
            changedValues = { ...values };
        }
        return changedValues;
    };

    const getFormData = (changedValues: Partial<FormValues>): FormData => {
        const fieldMapping: { [key: string]: string } = {
            fechaStart: 'fecha_start',
            horaStart: 'hora_start',
            fechaEnd: 'fecha_end',
            horaEnd: 'hora_end',
            imgHeader: 'img_header',
            imgPoster: 'img_poster'
        };

        let formData = new FormData();
        for (const key in changedValues) {
            let adjustedKey = fieldMapping[key] || key;
            if (changedValues[key] instanceof File) {
                formData.append(adjustedKey, changedValues[key], changedValues[key].name);
            } else {
                formData.append(adjustedKey, changedValues[key]);
            }
        }
        
        const { fechaStart = initialValues.fechaStart, horaStart = initialValues.horaStart } = changedValues;
        const eventStartDatetimeLocal = new Date(`${fechaStart}T${horaStart}:00`);
        const eventStartDatetimeUtc = eventStartDatetimeLocal.toISOString();
        if (initialValues.eventStartDatetimeUtc !== eventStartDatetimeUtc) {
            formData.append('event_start_datetime_utc', eventStartDatetimeUtc);
            formData.delete('fecha_start');
            formData.delete('hora_start');
        }
        const { fechaEnd = initialValues.fechaEnd, horaEnd = initialValues.horaEnd } = changedValues;
        const eventEndDateTimeLocal = new Date(`${fechaEnd}T${horaEnd}:00`);
        const eventEndDateTimeUtc = eventEndDateTimeLocal.toISOString();
        if (initialValues.eventEndDatetimeUtc !== eventEndDateTimeUtc) {
            formData.append('event_end_datetime_utc', eventEndDateTimeUtc);
            formData.delete('fecha_end');
            formData.delete('hora_end');
        }



        return formData;
    };

    const onSubmit = async (values: FormValues) => {
        const changedValues = getChangedValues(values, event);
        const formData = getFormData(changedValues);
        await submitForm(formData);

        if (!error) {
            // Show toast and then navigate
            toast({
                title: isUpdateMode ? 'Evento actualizado' : 'Evento creado',
                description: isUpdateMode ? 'El evento se actualizó correctamente.' : 'El evento se creó correctamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Wait for toast to disappear and then navigate
            navigate('/admin/eventos');

        }
    };

    return (
        <Card>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='nombre'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                                    <Input {...field} id='nombre' maxLength={128} />
                                    <FormHelperText>
                                        {field.value.length}/128 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='predio'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='predio'>Predio</FormLabel>
                                    <Select placeholder='Seleccione un predio' {...field} id='predio'>
                                        {
                                            predios.map((predio: Predio) => (
                                                <option key={predio.id} value={predio.id}>{predio.nombre}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='categoria'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='categoria'>Categoria</FormLabel>
                                    <Select placeholder='Seleccione un categoria' {...field} id='categoria'>
                                        {
                                            categorias.map((categoria: Categoria) => (
                                                <option key={categoria.id} value={categoria.id}>{categoria.nombre}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='edad'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='edad'>Edad minima</FormLabel>
                                    <NumberInput id='edad' min={0} max={99} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <DateField fieldName='fechaStart' label='Fecha de comienzo' />
                        <Field name='horaStart'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='horaStart'>Hora de comienzo</FormLabel>
                                    <div style={{ width: '100%', height: '35px', padding: '6px 12px', borderRadius: '4px', border: '1px solid #ced4da' }}>
                                        <TimePicker
                                            format='HH:mm'
                                            value={field.value}
                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                            clearIcon={null}
                                            clockIcon={null}
                                            className="time-picker-input"
                                        />
                                    </div>
                                </FormControl>
                            )}
                        </Field>
                        <DateField fieldName='fechaEnd' label='Fecha de finalización' />
                        <Field name='horaEnd'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='horaEnd'>Hora de finalización</FormLabel>
                                    <div style={{ width: '100%', height: '35px', padding: '6px 12px', borderRadius: '4px', border: '1px solid #ced4da' }}>
                                        <TimePicker
                                            format='HH:mm'
                                            value={field.value}
                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                            clearIcon={null}
                                            clockIcon={null}
                                            className="time-picker-input"
                                        />
                                    </div>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='habilitado'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='habilitado'>Habilitado</FormLabel>
                                    <Select
                                        placeholder='Seleccione una opción'
                                        {...field}
                                        id='habilitado'
                                        onChange={(e) => {
                                            form.setFieldValue(field.name, e.target.value === "true");
                                        }}
                                    >
                                        <option value="true">Si</option>
                                        <option value="false">No</option>
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        {/* <Flex h={'300px'} direction={{ base: 'column', md: 'row'}}>
                            <Field name='imgPoster'>
                                {({ field, form }: FieldProps) => (
                                    <FormControl isRequired={ true } mb="1rem">
                                        <FormLabel htmlFor='imgPoster'>Flyer</FormLabel>
                                            <Dropzone
                                                w={{ base: '100%', '2xl': '268px' }}
                                                me='36px'
                                                // maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                                                // minH={{ base: '100%', lg: '100%', '2xl': '100%' }}
                                                onDrop={(acceptedFiles: File[]) => {
                                                    form.setFieldValue(field.name, acceptedFiles[0]);
                                                }}
                                                content={
                                                    <Box>
                                                        <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
                                                        <Flex justify='center' mx='auto' mb='12px'>
                                                            <Text fontSize='xl' fontWeight='700' color={brandColor}>
                                                                Subir flyer
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Relación aspecto: 16/9
                                                        </Text>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Tamaño maximo: 500KB
                                                        </Text>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Archivos validos: PNG, JPEG o JPG
                                                        </Text>
                                                    </Box>
                                                }
                                            />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='imgHeader'>
                                {({ field, form }: FieldProps) => (
                                    <FormControl isRequired={ true } mb="1rem">
                                        <FormLabel htmlFor='imgHeader'>Encabezado</FormLabel>
                                            <Dropzone
                                                w={{ base: '100%', '2xl': '268px' }}
                                                me='36px'
                                                // maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                                                // minH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                                                onDrop={(acceptedFiles: File[]) => {
                                                    form.setFieldValue(field.name, acceptedFiles[0]);
                                                }}
                                                content={
                                                    <Box>
                                                        <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
                                                        <Flex justify='center' mx='auto' mb='12px'>
                                                            <Text fontSize='xl' fontWeight='700' color={brandColor}>
                                                                Subir encabezado
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Relación aspecto: 16/9
                                                        </Text>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Tamaño maximo: 500KB
                                                        </Text>
                                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                                            Archivos validos: PNG, JPEG o JPG
                                                        </Text>
                                                    </Box>
                                                }
                                            />
                                    </FormControl>
                                )}
                            </Field>
                        </Flex> */}
                        <Field name='imgPoster'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='imgPoster'>Flyer</FormLabel>
                                    <ImageUploader fieldName="imgPoster" orientation="vertical" />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='imgHeader'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='imgHeader'>Encabezado</FormLabel>
                                    <ImageUploader fieldName="imgHeader" orientation="horizontal" />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='desc'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='desc'>Descripción</FormLabel>
                                    <Textarea {...field} id='desc' maxLength={1000} />
                                    <FormHelperText>
                                        {field.value.length}/1000 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            {isUpdateMode ? 'Actualizar evento' : 'Crear evento'}
                        </Button>

                    </Form>
                )}
            </Formik>
        </Card>
    );
}