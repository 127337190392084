import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    NumberInput,
    NumberInputField,
    FormHelperText,
} from "@chakra-ui/react";
import Datetime from 'react-datetime';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldProps } from 'formik';
import Card from "components/card/Card";
import useFormSubmit from "../hooks/useFormSubmit";
import Ticket from "../../../models/Ticket";
import { useEffect } from "react";
import { format } from "date-fns";

export interface TicketFormProps {
    eventId: string;
}

export default function TicketForm(props: TicketFormProps) {
    const { eventId } = props;

    const toast = useToast();

    const navigate = useNavigate();

    const { submitForm, loading, submitted, error } = useFormSubmit(parseInt(eventId));
   
    const handleOnSubmit = async (data: Ticket) => {
        // Create a copy of the data object
        const filteredData = { ...data };

        // Remove the description field if it is blank or null
        if (!filteredData.description) {
            delete filteredData.description;
        }

        await submitForm(filteredData);
    }

    useEffect(() => {
        if (error && !loading) {
            toast({
                title: 'Error al crear el ticket',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!error && !loading && submitted) {
            toast({
                title: 'Ticket creado',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            navigate(`/admin/eventos/${eventId}`);
        }
    }, [submitted, error, loading, toast, eventId, navigate]);

    return (
        <Card>
            <Formik
                initialValues={{
                    tipo: '',
                    cantidad: 1,
                    precio: 100,
                    limite: 1,
                    privacidad: 'PUBLICO',
                    startsAt: '',
                    endsAt: '',
                    stock: true,
                    evento: parseInt(eventId),
                    description: ''
                }}
                onSubmit={handleOnSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='tipo'>
                            {({ field }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='tipo'>Nombre</FormLabel>
                                    <Input {...field} id='tipo' maxLength={32}/>
                                    <FormHelperText>
                                        {field.value.length}/32 caracteres
                                    </FormHelperText>
                                </FormControl>
                                
                            )}
                        </Field>
                        <Field name='cantidad'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='cantidad'>Cantidad</FormLabel>
                                    <NumberInput id='cantidad' min={1} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='precio'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='precio'>Precio</FormLabel>
                                    <NumberInput id='precio' min={100} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='limite'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='limite'>Limite por compra</FormLabel>
                                    <NumberInput id='limite' min={1} max={99} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='startsAt'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='startsAt'>Fecha y hora de inicio</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'startsAt', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='endsAt'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='endsAt'>Fecha y hora de fin</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'endsAt', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='privacidad'>
                            {({ field }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='privacidad'>Privacidad</FormLabel>
                                    <Select
                                        placeholder="Seleccione una opción"
                                        {...field}
                                        id='privacidad'
                                    >
                                        <option value='PUBLICO'>Publico</option>
                                        <option value='PRIVADO'>Privado</option>
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='stock'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='stock'>Stock</FormLabel>
                                    <Select
                                        placeholder="Seleccione una opción"
                                        {...field}
                                        id='stock'
                                        onChange={(e) => {
                                            form.setFieldValue(field.name, e.target.value === "true")
                                        }}
                                    >
                                        <option value='true'>Si</option>
                                        <option value='false'>No</option>
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='description'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ false } mb="1rem">
                                    <FormLabel htmlFor='description'>Descripción</FormLabel>
                                    <Input {...field} id='description' maxLength={128}/>
                                    <FormHelperText>
                                        {field.value.length}/128 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            Crear
                        </Button>
                    </Form>
                )}
            </Formik>
        </Card>
    )


}