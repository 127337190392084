

// Chakra imports
import {
    Box,
    Icon,
    SimpleGrid,
    Spinner,
    Flex,
    useColorModeValue
} from '@chakra-ui/react';

// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import TotalSales from './components/TotalSales';
import AgeBarPlot from './components/AgeBarPlot';


import IconBox from 'components/icons/IconBox';
import { MdAttachMoney, } from 'react-icons/md';
import PieCard from 'views/admin/reports/components/PieCard';

import SalesReports from './hooks/salesReports';

export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const {
        isLoading,
        dailySales,
        monthlySales,
        dailyKpi,
        monthlyKpi,
        genderCounts,
        ageCounts,
    } = SalesReports();

    // Get each total of daily sales into a list
    const dailySalesTotal = [{
        name: 'Ventas',
        data: dailySales.map((sale) => sale.total),
    }];
    const dailySalesPeriod = dailySales.map((sale) => sale.periodo.toLocaleDateString('es-ES', { day: 'numeric', month: 'short' }));
    const monthlySalesTotal = [{
        name: 'Ventas',
        data: monthlySales.map((sale) => sale.total),
    }];
    const monthlySalesPeriod = monthlySales.map((sale) => sale.periodo.toLocaleDateString('es-ES', { month: 'short' }));

    const dailyPercentage = `${((dailyKpi[0] / dailyKpi[1]) - 1 ) * 100}%`;
    const monthlyPercentage = `${((monthlyKpi[0] / monthlyKpi[1]) - 1 ) * 100}%`;
    const gendersCountDict = genderCounts.reduce((acc: { [key: string]: number }, item) => {
        acc[item.sexo] = item.count;
        return acc;
    }, {});

    const ageCountsTotal = [
        {
            name: 'Cantidad',
            data: ageCounts.map((item) => item.count),
        }
    ];
    const ageLabels = ageCounts.map((item) => item.age);

    if (isLoading) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner />
            </Flex>
        );
    }

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
                    startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
                    growth={dailyPercentage}
                    period='día'
                    name='Ventas de hoy'
                    value={`$${dailyKpi[0]}`}
                />
				<MiniStatistics
                    startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
                    growth={monthlyPercentage}
                    period='mes'
                    name='Ventas de este mes'
                    value={`$${monthlyKpi[0]}`}
                />
			</SimpleGrid>

			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                <TotalSales
                    ySeries={dailySalesTotal}
                    xSerie={dailySalesPeriod}
                    title='Ventas diarias'
                />
                <TotalSales
                    ySeries={monthlySalesTotal}
                    xSerie={monthlySalesPeriod}
                    title='Ventas mensuales'
                />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                <AgeBarPlot
                    xSerie={ageLabels}
                    ySeries={ageCountsTotal}
                />
                <PieCard
                    title="Ventas por genero"
                    data={gendersCountDict}
                />
			</SimpleGrid>
		</Box>
	);
}
