import { useParams } from 'react-router-dom';

// Chakra imports
import {
    Box,
    Flex,
    Text,
    Spinner,
    Divider,
} from '@chakra-ui/react';

import EventHook from './hooks/EventHook';
import EventInfo from './components/EventInfo';
import TicketsList from './components/TicketsList';
import InvitesList from './components/InvitesList';
import Card from 'components/card/Card';
import { apiFetch } from 'services/api';
import { useEffect, useState } from 'react';
import ProductsTable from './components/ProductsTable';
import ItemsCutTable from './components/ItemsCutTable';
import PromotionsTable from './components/PromotionsTable';
import NoUserInviteTable from './components/NoUserInviteSection';


export default function EventPage() {

    const { id } = useParams();
    
    const {
        isLoading,
        event,
        tickets,
        invites,
        products,
        ticketCuts,
        productCuts,
        promotions,
        noUserInvite,
    } = EventHook(Number(id));

    if (!event && !isLoading) {
        return (
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
                <Flex justifyContent='center' alignItems='center' height='100vh'>
                    <Text>Evento no encontrado</Text>
                </Flex>
            </Box>
        );
    }

    // concat ticketCuts and productCuts
    const itemsCut = ticketCuts.concat(productCuts);

    return (
        <Flex justifyContent='center' alignItems='center'>
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }} w='100%'>
                {
                    (
                        isLoading
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Spinner />
                        </Flex>
                        : <Flex direction={{ base: 'column', md: 'column' }}>
                            <TicketSaleInfo event_id={event.id} />
                            <MercadoPagoSales event_id={event.id} />
                            <EventInfo event={event}/>
                            <TicketsList tickets={tickets} eventId={event.id} />
                            <InvitesList invites={invites} />
                            {/* <NoUserInviteTable eventId={event.id} noUserInvites={noUserInvite} /> */}
                            <ProductsTable eventId={event.id} products={products} />
                            <PromotionsTable eventId={event.id} promotions={promotions} />
                            <ItemsCutTable itemsCut={itemsCut} />
                        </Flex>
                    )
                }
            </Box>
        </Flex>
    );
}

function MercadoPagoSales({event_id}: any) {
    // sales dictionary
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const fetchSales = async () => {
            try {
                const response = await apiFetch(
                    `/analytics/get-mercado-pago-sales?event_id=${event_id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    }
                );
                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error(error);

            } finally {
                setIsLoading(false);
            }
        }
        fetchSales();
    }, [event_id, token]);

    if (isLoading) {
        return <></>;
    }

    return (
        <Flex justifyContent='center' alignItems='center'>
            <Box w='100%' mt='20px'>
                <Card alignItems='center' flexDirection='column' w='100%' mb="1rem">
                    <Flex align='center' w='100%' px='15px' py='10px'>
                        <Text me='auto' fontSize='xl' fontWeight='700' lineHeight='100%'>
                            Montos de venta por Mercado Pago
                        </Text>
                    </Flex>
                    <Divider />
                    <Flex justifyContent='center' alignItems='center' w='100%'>
                        <Box w='50%' p='10px'>
                            <Text fontSize='lg' fontWeight='700' lineHeight='100%'>
                                Monto bruto
                            </Text>
                            <Text>
                                ${data.total_brute}
                            </Text>
                        </Box>
                        <Box w='50%' p='10px'>
                            <Text fontSize='lg' fontWeight='700' lineHeight='100%'>
                                Monto neto
                            </Text>
                            <Text>
                                ${data.total_net}
                            </Text>
                        </Box>
                    </Flex>
                </Card>
            </Box>
        </Flex>
    );
}

function TicketSaleInfo({event_id}: any) {

    const [sales, setSales] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const token = sessionStorage.getItem('token');

    // fetch api analytics/get_ordered_ticket_by_event
    useEffect(() => {
        const fetchSales = async () => {
            try {
                const response = await apiFetch(
                    `/analytics/get-ordered-ticket-by-event?event_id=${event_id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    }
                );
                const data = await response.json();
                setSales(data);
            } catch (error) {
                console.error(error);

            } finally {
                setIsLoading(false);
            }
        }
        fetchSales();
    }, [event_id, token]);

    if (isLoading) {
        return <></>;
    }
    
    return (
        <Flex justifyContent='center' alignItems='center'>
            <Box w='100%' mt='20px'>
                <Card alignItems='center' flexDirection='column' w='100%' mb="1rem">
                    <Flex align='center' w='100%' px='15px' py='10px'>
                        <Text me='auto' fontSize='xl' fontWeight='700' lineHeight='100%'>
                            Venta de boletos
                        </Text>
                    </Flex>
                    <Divider />
                    <Flex justifyContent='center' alignItems='center' w='100%'>
                        {
                            sales.map((sale, index) => (
                                <Box key={index} w='50%' p='10px'>
                                    <Text fontSize='lg' fontWeight='700' lineHeight='100%'>
                                        {sale.entrada__tipo}
                                    </Text>
                                    <Text>
                                        {sale.count}
                                    </Text>
                                </Box>
                            ))
                        }
                    </Flex>
                </Card>
            </Box>
        </Flex>
    );
}